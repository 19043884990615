import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import Image from 'next/image'

import style from './TextBannerCarousel.module.css'

import { useEffect, useState } from 'react'
import { getMainTextBannerConfig } from '@/lib/supabaseApi'
import { Container } from '@mui/material'
import { Autoplay } from 'swiper'
import ReactMarkdown from 'react-markdown'

export default function TextBannerCarousel(props: { isMobile: boolean }) {
  const [texts, setTexts] = useState<string[] | null>(null)
  const [timeTransition, setTimeTransition] = useState<number>(3)
  const [web, setWeb] = useState<boolean>(false)
  const [mobile, setMobile] = useState<boolean>(false)

  useEffect(() => {
    getMainTextBannerConfig().then((config) => {
      try {
        if (config && config[0] && config[0].value) {
          const data = JSON.parse(config[0].value)
          setTexts(data.msgList)
          setTimeTransition(data.timeTransition)
          setWeb(data.checkWeb)
          setMobile(data.checkMobile)
        }
      } catch (error) {
        setTexts(null)
      }
    })
  }, [])

  const canRender = () => {
    if (props.isMobile) {
      return mobile
    } else {
      return web
    }
    
  }

  return (
    (texts && texts?.length > 0 && canRender()) ? <Container className={style.container}>
    {
      <Swiper
        slidesPerView={'auto'}
        autoplay={{
          delay: (texts.length === 1) ? 3600 * 1000 : 1000 * timeTransition,
          disableOnInteraction: false,
        }}
        loop={true}
        spaceBetween={10}
        className={style.swiper}
        modules={[Autoplay]}
      >
        {texts?.map((msg, index) => {
          return (
            <SwiperSlide key={index} className={style.slide}>
              <div className={style.msg}>
                <ReactMarkdown>{msg}</ReactMarkdown>
              </div>
            </SwiperSlide>)
        })}
      </Swiper >
    }
  </Container>
    : null
  )
}
