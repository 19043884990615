import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import axios from 'axios';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export default function UpdatePage() {
  const { t } = useTranslation()

  const [showUpdate, setShowUpdate] = useState(false);
  const [device, setDevice] = useState('android')

  useEffect(() => {
	const userAgent = navigator.userAgent.toLowerCase();
	const isAndroid = userAgent.includes('android')
	const isIOS = userAgent.includes('iphone') || userAgent.includes('ipad')

	if (isAndroid) {
	  setDevice('android')
	} else if (isIOS) {
	  setDevice('ios')
	}

	var options = {
	  method: 'GET',
	  url: (process.env.NEXT_PUBLIC_URL_CHECK_VERSION) ? process.env.NEXT_PUBLIC_URL_CHECK_VERSION : '/api/check-device-version'
	}

	axios.request(options).then(function (response) {
	if (response?.data?.message === 'ko') {
	  setShowUpdate(true)
	}
	}).catch(function (error) {
	  setShowUpdate(false)
	});
  }, [])

  const handleShowUpdate = () => {
	setShowUpdate(false)
  }

  return ((showUpdate) ? 
	<div style={{
		backgroundColor: '#ffffff',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '1%'
	}}>
		<IconButton aria-label='close' size='medium' style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleShowUpdate}>
			<CancelIcon />
		</IconButton>
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '30px' }}>
			<span style={{ fontSize: '24px', fontWeight: 700, textAlign: 'end' }}>{t('pages.update_mobile.update_need')}</span>
		</div>
		<div style={{ fontSize: '18px', textAlign: 'center', padding: '18px' }}>{t('pages.update_mobile.update_info')}</div>
		{/* <a style={{ margin: '6px 0', fontSize: '16px', color: 'white', backgroundColor:'#0e046d', padding: '12px 16px', borderRadius: '16px' }}
		  href={(device === 'ios') ? 'itms-apps://itunes.apple.com/app/id6443994877' : 'https://play.google.com/store/apps/details?id=com.syniva.syniva'}
		>
			<b>{t('pages.update_mobile.update_button')}</b>
		</a> */}
		<div style={{ fontWeight: 200, display: 'flex', flexDirection: 'column', paddingBottom: '30px' }}>
			<b style={{ fontSize: '24px', textAlign: 'center' }}>syniva</b>
			<span style={{ fontSize: '18px', textAlign: 'center' }}>{t('pages.update_mobile.update_hashtag')}</span>
		</div>
	</div>: null
  ) 
}
