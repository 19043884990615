import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import Image from 'next/image'

import style from './MainMobileCarousel.module.css'

// import { Autoplay, Pagination, Navigation } from 'swiper'
import { useEffect, useState } from 'react'
import { getMainImages } from '@/lib/supabaseApi'
import { Container } from '@mui/material'
import { Autoplay } from 'swiper'

// TODO: Try to implement `fade` effect. It seems like there are many problems with it
// https://github.com/nolimits4web/swiper/issues/3622

export default function MainMobileCarousel() {
  const [images, setImages] = useState<string[] | null>(null)
  const [timeTransition, setTimeTransition] = useState<number>(3)

  useEffect(() => {
    getMainImages().then((imgs) => {
      try {
        if (imgs && imgs[0] && imgs[0].value) {
          const config = JSON.parse(imgs[0].value)
          setImages(config.imgList)
          setTimeTransition(config.timeTransition)
        }
      } catch (error) {
        setImages(null)
      }
    })
  }, [])

  return (
    images ? <Container className={style.container}>
    {
      <Swiper
        slidesPerView={'auto'}
        autoplay={{
          delay: (images.length === 1) ? 3600 * 1000 : 1000 * timeTransition,
          disableOnInteraction: false,
        }}
        loop={true}
        spaceBetween={10}
        className={style.swiper}
        modules={[Autoplay]}
      >
        {images?.map((img, index) => {
          return (
            <SwiperSlide key={index} className={style.slide} style={{
              backgroundImage: `url("${img}${'?' + Date.now().toLocaleString()}")`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center'
            }} />
          )
        })}
      </Swiper >
    }
  </Container>
    : <>
        <Image src='/images/img-mobile.jpg' alt='Mobile img' layout='fill' objectFit='cover' />
    </>
  )
}
