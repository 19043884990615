import { Box } from '@mui/material'
import styles from './Footer.module.css'
import Image from 'next/image'
// import useMediaQuery from '@mui/material/useMediaQuery'
import Legal from '../footer/legal'
import Social from '../footer/social'
import BadgesApp from '../footer/badges_app'

export default function Footer() {
  /*
  const matches = useMediaQuery('(min-width:700px)')

  function ECommerce() {
    return (
      <div
        className={styles.footer__ecommerce}
      >
        <Image src={'/images/ecommercetrustmark.png'} alt="ecommerce trustmark" width={80} height={70} />
        <Image src={'/images/confianza-online.png'} alt="confianza online" width={70} height={70} />
      </div>
    )
  }

  function TrustpilotWidget() {
    return (
      <Image src="/images/trustpilot-widget-example.png" alt="trustpilot" width={140} height={70} />
    )
  }
  */

  return (
    <footer className={styles.footer}>
      {/* HIDDEN UNTIL INTEGRATED... */}
      {/* {matches && <TrustpilotWidget />} */}
      <Box className={styles.footer__links}>
        <Social />
        <Legal />
      </Box>
      <BadgesApp />
      <a href='https://www.confianzaonline.es/empresas/syniva.htm' target="_blank" rel="noopener noreferrer">
        <Image className={styles.footer__trustseal} src="/images/confianza-online.png" alt="trustseal" width={'85%'} height={'85%'} />
      </a>
      {/* <ECommerce /> */}
    </footer>
  )
}
