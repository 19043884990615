import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import style from './BannersCarousel.module.scss'

import { Autoplay, Pagination, Navigation } from 'swiper'
import { useEffect, useState } from 'react'
import { Banner } from '@/types/supabase.types'
import { getBanners } from '@/lib/supabaseApi'

// TODO: Try to implement `fade` effect. It seems like there are many problems with it
// https://github.com/nolimits4web/swiper/issues/3622

export default function Carousel() {
  const [banners, setBanners] = useState<Array<Banner[]> | null>()

  useEffect(() => {
    getBanners().then((banners) => {
      if (banners) {
        setBanners(banners)
      }
    })
  }, [])

  return (
    banners ?
    <Swiper
      slidesPerView={1}
      autoplay={{
        delay: 5000, //TODO: Refactor this
        disableOnInteraction: true,
      }}
      spaceBetween={100}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className={style.swiper}
      direction='vertical'
      breakpoints={{
        465: {
          direction: 'horizontal',
          slidesPerView: banners.length,
        },
        768: {
          slidesPerView: banners.length,
          direction: 'horizontal',
          spaceBetween: 30,
        },
      }}
    >
      {banners && banners.map((child, index) => {
        return <>
          <SwiperSlide key={index}>
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              slidesPerView={1}
              autoplay={{
                // delay: 2000, //TODO: Refactor this. Default component value is 3000
                disableOnInteraction: true,
              }}
              loop={child.length > 1}
              spaceBetween={100}
              pagination={false}
              className={style.swiper__child}
              direction='vertical'
              breakpoints={{
                465: {
                  direction: 'horizontal',
                  slidesPerView: 1,
                  autoplay: {
                    disableOnInteraction: false
                  }
                },
                768: {
                  slidesPerView: 1,
                  direction: 'horizontal',
                  spaceBetween: 30,
                  autoplay: {
                    disableOnInteraction: false
                  }
                },
              }}
            >
              {child.length !== 0 && child.map((banner, index) => {
                return (
                  <SwiperSlide key={index}
                    className={style.slide}
                    style={{
                      backgroundImage: `url("${banner.banner_url}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      cursor: banner.redirect_url ? 'pointer' : ''
                    }}
                    onClick={() => banner?.redirect_url && window.location.replace(banner.redirect_url)}
                  />
                )
              })}
            </Swiper>

          </SwiperSlide>
        </>

      })}
    </Swiper >
    :
    null
  )
}
